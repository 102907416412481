<template>
  <v-select
    v-model="valueMutation"
    class=""
    :items="selectList"
    item-value="id"
    item-text="name"
    placeholder="Выберите рассылку"
    outlined
    hide-details
    :loading="loadingAction"
    v-bind="$attrs"
    v-on="$listeners"
  />
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'

  export default {
    name: 'NotificatonSelect',
    components: {

    },
    model: {
      prop: 'value',
      event: 'update',
    },
    props: {
      value: { type: [Number, Object, Array], default: undefined },
      filterItems: { type: [Function], default: undefined },

    },
    data () {
      return {
        loadingAction: false,

      }
    },
    computed: {
      ...mapGetters({
        programId: 'programId',
        notificationPickList: 'company/notifications/notificationPickList',
      }),
      selectList () {
        if (this.filterItems) {
          return this.notificationPickList.filter(this.filterItems)
        } else {
          return this.notificationPickList
        }
      },
      valueMutation: {
        get: function () {
          return this.value
        },
        set: function (v) {
          this.$emit('update', v)
        },
      },
    },
    watch: {
      programId (v) {
        this.startAction()
      },
    },
    created () {
      this.startAction()
    },

    methods: {
      ...mapActions({
        getPickList: 'company/notifications/getPickList',
      }),
      async startAction () {
        if (this.loadingAction) return
        try {
          this.loadingAction = true
          await this.getPickList(this.programId)
          // if (this.selectList.length > 0 && !this.valueMutation) this.valueMutation = this.selectList[0].id
        } catch (error) {
          console.error(error)
        } finally {
          this.loadingAction = false
        }
      },

    },
  }
</script>
