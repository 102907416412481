<template>
  <v-menu
    v-model="internalShow"
    absolute
    :max-height="600"
    :close-on-content-click="false"
  >
    <template v-slot:activator="{ on, attrs }">
      <slot
        name="activator"
        :on="on"
        :attrs="attrs"
      />
    </template>
    <v-list>
      <v-list-item
        v-for="(item, idx) in subtitationItems"
        :key="'fixed-' + idx"
        link
        class="main-menu-item"
        active-class="main-menu-item-active"
        @click="copyInBuffer(item.value)"
      >
        <v-list-item-content
          class="item-content"
        >
          <v-list-item-title>
            <span class="body-m-medium">{{ item.text }}</span>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-group
        v-for="(item, idx) in subtitationGroupItems"
        :key="'group-' + idx"
        class="main-menu-item-group"
        :value="false"
        active-class="main-menu-item-group-active"
        no-action
      >
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title>
              <v-icon v-if="item.is_main">
                mdi-star
              </v-icon>
              <span class="body-m-medium neutral-500--text">{{ item.text }}</span>
            </v-list-item-title>
          </v-list-item-content>
        </template>
        <template
          v-for="(subItem, subIdx) in item.values"
        >
          <v-list-group
            v-if="subItem.values"
            :key="'sub-' + idx + ':' + subIdx"
            class="main-menu-item-group item-child"
            :value="false"
            active-class="main-menu-item-group-active"
            no-action
            sub-group
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title><span class="body-m-medium neutral-500--text">{{ subItem.text }}</span></v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item
              v-for="(subItem2, subIdx2) in subItem.values"
              :key="'sub-' + idx + ':' + subIdx + subIdx2"
              link
              class="main-menu-item item-child"
              active-class="main-menu-item-active"
              @click="copyInBuffer(subItem2.value)"
            >
              <v-list-item-content
                class="item-content"
              >
                <v-list-item-title>
                  <span class="body-m-medium">{{ subItem2.text }}</span>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
          <v-list-item
            v-else
            :key="'sub-' + idx + ':' + subIdx"
            link
            class="main-menu-item item-child"
            active-class="main-menu-item-active"
            @click="copyInBuffer(subItem.value)"
          >
            <v-list-item-content
              class="item-content"
            >
              <v-list-item-title>
                <span class="body-m-medium">{{ subItem.text }}</span>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
        <!-- <v-list-item
          v-for="(subItem, subIdx) in item.values"
          :key="'sub-' + idx + ':' + subIdx"
          link
          class="main-menu-item item-child"
          active-class="main-menu-item-active"
          @click="copyInBuffer(subItem.value)"
        >
          <v-list-item-content
            class="item-content"
          >
            <v-list-item-title>
              <span class="body-m-medium">{{ subItem.text }}</span>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item> -->
      </v-list-group>
    </v-list>
  </v-menu>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    model: {
      prop: 'show',
      event: 'change',
    },
    props: {
      show: Boolean,
    },
    data () {
      return {
        internalShow: false,
      }
    },
    computed: {
      ...mapGetters({
        programId: 'programId',
        bonusUnits: 'company/bonus_units/activeBonusUnits',
      }),
      additionalFields () {
        return this.$store.getters['settings/account-fields/fields'][this.programId] || []
      },
      subtitationItems () {
        const items = [
          { value: 'id', text: 'ID' },
          { value: 'number', text: 'Номер карты' },
          { value: 'created_at', text: 'Дата регистрации' },
          { value: 'birthday', text: 'День рождения клиента' },
          { value: 'client_name', text: 'Имя клиента' },
          { value: 'user.name', text: 'Имя владельца' },
          { value: 'user.lastname', text: 'Фамилия владельца' },
          { value: 'user.middlename', text: 'Отчество владельца' },
          { value: 'user.fio', text: 'ФИО владельца' },
          { value: 'refer_code', text: 'Реферальный код' },
          { value: 'refer_link', text: 'Реферальная ссылка' },
          // { value: 'refer_link_short', text: 'Короткая реферальная ссылка' },
        ]

        return items
      },
      subtitationGroupItems () {
        const items = []
        items.push(
          {
            text: 'Доп поля',
            values: this.additionalFields.map(item => {
              return { value: `fields[${item.id}]`, text: item.name }
            }),
          },
        )
        this.bonusUnits.forEach(bu => {
          items.push(
            {
              is_main: bu.is_main,
              text: bu.name,
              values: [
                { value: `bonus_unit_balances[${bu.id}].balance_str`, text: 'Баланс' },
                { value: `bonus_unit_balances[${bu.id}].name_pluralize`, text: 'Название' },
                { value: `bonus_unit_balances[${bu.id}].balance_full`, text: 'Баланс и название' },
              ],
            },
          )
        })

        // payload
        items.push({
          text: 'События',
          values: [
            {
              text: 'Бонусная операция',
              values: [
                { text: 'Величина', value: 'payload.valueStr' },
                { text: 'Величина и склонение', value: 'payload.valueStrFull' },
              ],
            },
          ],
        })

        return items
      },
    },
    watch: {
      internalShow (v) {
        this.$emit('change', v)
      },
      show (v) {
        this.internalShow = v
      },
    },
    methods: {
      async copyInBuffer (v) {
        this.internalShow = false
        this.$emit('clickOnItem', `{{${v}}}`)

        await this.$copyText(`{{${v}}}`)
        this.$notify({
          type: 'success',
          title: 'Подстановки',
          text: 'Скопировано в буфер обмена',
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
@import "@/styles/vuetify-preset-plus/light_theme/_variables.sass";

.main-menu-item-group {
  & ::v-deep {
    .v-list-group__header {
      min-height: 45px;
      padding-left: 20px;
      padding-right: 20px;
      &:focus::before,
      &:hover::before {
        opacity: 0;
      }

      .v-list-item__icon.v-list-group__header__append-icon {
        .v-icon{
          font-size: 15px;
          color: $primary-base;
        }
      }
      .v-list-group__header__append-icon{
        min-width: 36px;
        justify-content: center;
      }
   }
  }
}

.main-menu-item {
  min-height: 46px;
  padding-left: 20px;
  padding-right: 20px;
  color: $neutral-500;
  &:hover,
  &:active,
  &:focus,
  &:visited,
  &.v-list-item--active > &::before,
  & {
    background-color: transparent !important;
    color: transparent !important;
  }

  &:focus::before,
  &:hover::before {
    opacity: 0;
  }

  .item-content {
    color: $neutral-500;
  }
  &.main-menu-item-active {
    color: $primary-base;
    border-right: 2px solid $primary-base;
    border-radius: 2px;
    .item-content {
      color: $primary-base;
    }
  }

  &.item-child {
    margin-left: 30px;
    padding-left: 22px!important;
    border-left: 1px solid $neutral-300;
    .item-content {
      padding-top: 8px;
      padding-bottom: 8px;
    }
  }
}

</style>
