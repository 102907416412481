<template>
  <div>
    <BaseMasterFieldBlock title="Содержание">
      <template v-slot:description>
        <subtitation-menu>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              style="margin-left: 14px"
              color="secondary"
              :text="true"
              :ripple="false"
              v-bind="attrs"
              v-on="on"
            >
              Подстановки
            </v-btn>
          </template>
        </subtitation-menu>
      </template>
      <template v-slot:input>
        <subtitation-menu
          v-model="showContextMenu.title"
          @clickOnItem="pasteInTextArea($refs.fieldBody, $event)"
        >
          <template v-slot:activator="{ on }">
            <base-text-area
              ref="fieldBody"
              v-model="stepperData.content.body"
              :rules="rules.contentBody"
              :export-file-name="stepperData.name"
              placeholder="Введите содержание"
              counter
              @contextmenu="contextMenuSubtitation($event, 'body', on)"
              @blur="stepperData.content.body = stepperData.content.body ? stepperData.content.body.trim() : null"
            >
              <template v-slot:counter>
                <div class="d-flex flex-column">
                  <p class="body-s-regular neutral-600--text mb-0">
                    Кол-во символов: {{ strWithoutTemplate(stepperData.content.body).length }}
                  </p>
                </div>
              </template>
            </base-text-area>
          </template>
        </subtitation-menu>
      </template>
    </BaseMasterFieldBlock>
  </div>
</template>

<script>
  import SubtitationMixin from './subtitation'
  import SubtitationMenu from './components/SubtitationMenu'
  import Vue from 'vue'

  export default {
    components: {
      SubtitationMenu,
    },
    mixins: [SubtitationMixin],
    props: {
      stepperData: {
        type: Object,
        default: () => {
          return {}
        },
      },
    },
    inject: ['rules'],
    mounted () {
      Vue.set(this.stepperData, 'content', Object.assign({
        body: null,
      }, this.stepperData.content))
    },
    methods: {
      getLetterCount (str) {
        return new TextEncoder().encode(this.strWithoutTemplate(str)).length
      },
      strWithoutTemplate (str) {
        return str ? str.replace(/{{\s*[\d\w\s\\.\\[\]]+\s*}}/g, '') : ''
      },
    },
  }
</script>

<style lang="scss" scoped>

</style>
