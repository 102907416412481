import { mapActions } from 'vuex'

export default {
  data () {
    return {
      editorValue: {},
      editorMode: 0,
      htmlText: '',
      loadingSend: false,
    }
  },
  computed: {
    programId () {
      return this.$store.getters.programId
    },
  },
  watch: {
    editorValue: {
      deep: true,
      handler (v) {
        this.stepperData.content.body = v.html
        if (Object.keys(v.design).length) {
          console.log('editorValue', v)
          this.stepperData.content.design = v.design
        }
      },
    },
  },
  methods: {
    ...mapActions({
      sendContentNotification: 'company/notifications/sendContentNotification',
    }),
    async clickSendTemplateToYourself (item) {
      this.loadingSend = true
      await this.sendContentNotification(
        {
          channelType: this.stepperData.channel_type,
          content: this.stepperData.content,
          programId: this.programId,
        },
      )
      this.loadingSend = false
    },
    loadEmailEditor () {
      if ((this.isEdit || this.isCopy) &&
        this.stepperData.content.design) {
        this.$refs.htmleditor.unlayerLoadDesign({ ...this.stepperData.content.design })
      }
    },
  },
}
