<template>
  <div>
     <BaseMasterFieldBlock title="Заголовок">
      <template v-slot:description>
        Заголовок PUSH-уведомления.
        <subtitation-menu>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              style="margin-left: 14px"
              color="secondary"
              :text="true"
              :ripple="false"
              v-bind="attrs"
              v-on="on"
            >
              Подстановки
            </v-btn>
          </template>
        </subtitation-menu>
      </template>
      <template v-slot:input>
        <subtitation-menu
          v-model="showContextMenu.title"
          @clickOnItem="pasteInTextField($refs.fieldTitle, $event)"
        >
          <template v-slot:activator="{ on }">
            <base-text-field
              ref="fieldTitle"
              v-model="stepperData.content.title"
              placeholder="Введите название заголовка"
              :rules="rules.contentTitle"
              :maxlength="MAX_LENGTH_TITLE"
              validation-placement="top"
              error-style="custom"
              @contextmenu="contextMenuSubtitation($event, 'title', on)"
              @blur="stepperData.content.title = stepperData.content.title ? stepperData.content.title.trim() : null"
            />
          </template>
        </subtitation-menu>
      </template>
    </BaseMasterFieldBlock>

    <BaseMasterFieldBlock title="Содержание">
      <template v-slot:description>
        <subtitation-menu>
          <template v-slot:activator="{ on, attrs }">
            <div class="d-flex align-center justify-space-between">
              <v-btn
                style="margin-left: 14px"
                color="secondary"
                :text="true"
                :ripple="false"
                v-bind="attrs"
                v-on="on"
              >
                Подстановки
              </v-btn>
              <v-btn
                style="margin-left: 14px"
                color="secondary"
                :text="false"
                :ripple="true"
                @click="clickSendTemplate"
                :loading="loadingSend"
                :disabled="(stepperData.content.body == null || stepperData.content.body == '') ? true : false"
              >
                Отправить себе
              </v-btn>
            </div>
          </template>
        </subtitation-menu>
      </template>
      <template v-slot:input>
        <subtitation-menu
          v-model="showContextMenu.title"
          @clickOnItem="pasteInTextArea($refs.fieldBody, $event)"
        >
          <template v-slot:activator="{ on }">
            <base-text-area
              :maxlength="MAX_LENGTH_BODY"
              ref="fieldBody"
              v-model="stepperData.content.body"
              :rules="rules.contentBody"
              :export-file-name="stepperData.name"
              placeholder="Введите содержание"
              counter
              @contextmenu="contextMenuSubtitation($event, 'body', on)"
              @blur="
                stepperData.content.body = stepperData.content.body
                  ? stepperData.content.body.trim()
                  : null
              "
            >
              <!-- <template v-slot:counter>
                <div class="d-flex flex-column">
                  <p class="body-s-regular neutral-600--text mb-0">
                    Кол-во символов:
                    {{ getLetterCount(stepperData.content.body) }} /
                    {{ MAX_LENGTH_BODY }}
                  </p>
                </div>
              </template> -->
            </base-text-area>
          </template>
        </subtitation-menu>
      </template>
    </BaseMasterFieldBlock>
  </div>
</template>

<script>
import SubtitationMixin from "./subtitation";
import SubtitationMenu from "./components/SubtitationMenu";
import Vue from "vue";
import { mapActions } from "vuex";

export default {
  components: {
    SubtitationMenu,
  },
  mixins: [SubtitationMixin],
  props: {
    stepperData: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  inject: ["rules"],
  mounted() {
    Vue.set(
      this.stepperData,
      "content",
      Object.assign(
        {
          body: null,
        },
        this.stepperData.content
      )
    );
  },
  data() {
    return {
      MAX_LENGTH_TITLE: 255,
      MAX_LENGTH_BODY: 1024,
      loadingSend: false,
    };
  },
  methods: {
    ...mapActions({
      sendContentNotification: "company/notifications/sendContentNotification",
    }),
    async clickSendTemplate() {
      this.loadingSend = true;
      await this.sendContentNotification({
        channelType: this.stepperData.channel_type,
        content: { body: this.stepperData.content.body },
        programId: this.programId,
      });
      this.loadingSend = false;
    },
    getLetterCount(str) {
      return new TextEncoder().encode(this.strWithoutTemplate(str)).length;
    },
    strWithoutTemplate(str) {
      return str && str.replace(/{{\s*[\d\w\s\\.\\[\]]+\s*}}/g, "");
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
