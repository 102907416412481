<template>
  <div>
    <BaseMasterFieldBlock
      title="Заголовок"
      description="Заголовок рассылки, который увидит клиент."
    >
      <template v-slot:description>
        Заголовок рассылки, который увидит клиент.
        <subtitation-menu>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              style="margin-left: 14px"
              color="secondary"
              :text="true"
              :ripple="false"
              v-bind="attrs"
              v-on="on"
            >
              Подстановки
            </v-btn>
          </template>
        </subtitation-menu>
      </template>
      <template v-slot:input>
        <subtitation-menu
          v-model="showContextMenu.title"
          @clickOnItem="pasteInTextField($refs.fieldTitle, $event)"
        >
          <template v-slot:activator="{ on }">
            <base-text-field
              ref="fieldTitle"
              v-model="stepperData.content.title"
              placeholder="Введите название заголовока"
              :rules="rules.contentTitle"
              validation-placement="top"
              @contextmenu="contextMenuSubtitation($event, 'title', on)"
              @blur="stepperData.content.title = stepperData.content.title ? stepperData.content.title.trim() : null"
            />
          </template>
        </subtitation-menu>
      </template>
    </BaseMasterFieldBlock>
    <BaseMasterFieldBlock
      title="Прехедер"
      description="Описание для прехедера."
    >
      <template v-slot:description>
        Это специальная строка, которая не отображается в самом сообщении, но видна в списке всех сообщений в почтовой программе клиента.
        <subtitation-menu>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              style="margin-left: 14px"
              color="secondary"
              :text="true"
              :ripple="false"
              v-bind="attrs"
              v-on="on"
            >
              Подстановки
            </v-btn>
          </template>
        </subtitation-menu>
      </template>
      <template v-slot:input>
        <subtitation-menu
          v-model="showContextMenu.description"
          @clickOnItem="pasteInTextArea($refs.fieldDescription, $event)"
        >
          <template v-slot:activator="{ on }">
            <base-text-field
              ref="fieldDescription"
              v-model="stepperData.content.description"
              placeholder="Введите прехедер"
              :rules="rules.contentDescription"
              validation-placement="top"
              @contextmenu="contextMenuSubtitation($event, 'description', on)"
              @blur="stepperData.content.description = stepperData.content.description ? stepperData.content.description.trim() : null"
            />
            <!-- <base-text-area
              ref="fieldDescription"
              :value="stepperData.content.description"
              :rules="rules.contentDescription"
              placeholder="Введите прехедер"
              @contextmenu="contextMenuSubtitation($event, 'description', on)"
              @input="stepperData.content.description = $event.replace(/(^\s*|\s+$)/, '')"
            /> -->
          </template>
        </subtitation-menu>
      </template>
    </BaseMasterFieldBlock>
    <BaseMasterFieldBlock
      title="Содержание"
      description=""
    >
      <template v-slot:input>
        <v-row no-gutters>
          <v-col class="pa-0">
            <base-html-editor
              ref="htmleditor"
              v-model="editorValue"
              :export-file-name="stepperData.name"
              :html-text.sync="htmlText"
              :mode.sync="editorMode"
              :rules="rules.contentBody"
              @loadEmailEditor="loadEmailEditor"
            >
              <template v-slot:append-action>
                <subtitation-menu>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="secondary"
                      v-bind="attrs"
                      v-on="on"
                    >
                      Подстановки
                    </v-btn>
                  </template>
                </subtitation-menu>
              </template>
              <template v-slot:action>
                <v-btn
                  color="secondary"
                  style="margin-left: 14px;"
                  :loading="loadingSend"
                  @click="clickSendTemplateToYourself"
                >
                  Отправить себе
                </v-btn>
              </template>
            </base-html-editor>
          </v-col>
        </v-row>
      </template>
    </BaseMasterFieldBlock>
  </div>
</template>

<script>
  import HtmlEditorMixin from './html-editor'
  import SubtitationMixin from './subtitation'
  import SubtitationMenu from './components/SubtitationMenu'
  import Vue from 'vue'

  export default {
    components: {
      SubtitationMenu,
    },
    mixins: [HtmlEditorMixin, SubtitationMixin],
    props: {
      stepperData: {
        type: Object,
        default: () => {
          return {}
        },
      },
    },
    inject: ['isEdit', 'isCopy', 'rules'],
    computed: {
      program () {
        return this.$store.getters['company/program/program']
      },
    },
    mounted () {
      Vue.set(this.stepperData, 'content', Object.assign({
        title: this.program.name,
        description: null,
        body: null,
      }, this.stepperData.content))

      if (this.isEdit || this.isCopy) {
        this.htmlText = this.stepperData.content.body
      }

      // if (!this.stepperData.content.title?.length) {
      //   this.stepperData.content.title = this.program.name
      // }
    },

  }
</script>

<style lang="scss" scoped>

</style>
