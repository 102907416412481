<template>
  <div style="height: 100%;">
    <v-container class="container__main-step">
      <v-row
        align="center"
        justify="center"
      >
        <v-col>
          <v-form
            ref="form"
            v-model="isFormValid"
          >
            <BaseMasterFieldBlock
              title="Название"
              description="Название рассылки, используемое только внутри кабинета (ваши клиенты его не увидят)."
            >
              <template v-slot:input>
                <base-text-field
                  :value="stepperData.name"
                  class="field__name"
                  placeholder="Название"
                  :rules="rules.name"
                  validation-placement="top"
                  maxlength="255"
                  @input="setStepperData('name', $event.replace(/(^\s*|\s+$)/, ''))"
                />
              </template>
            </BaseMasterFieldBlock>
            <BaseMasterFieldBlock
              title="Тип рассылки"
              description="В каком виде клиенту будет доставлена рассылка."
            >
              <template v-slot:input>
                <v-select
                  v-model="stepperData.channel_type"
                  :rules="rules.channel_type"
                  class="field__channel-type"
                  :items="channelTypes"
                  item-value="value"
                  item-text="text"
                  placeholder="Выберите тип рассылки"
                  outlined
                  hide-details
                  :clearable="!isEdit"
                  :readonly="isEdit"
                />
              </template>
            </BaseMasterFieldBlock>
            <BaseMasterFieldBlock
              title="Переотправка"
              description="Выберите рассылку для отправки в случае если данная рассылка не будет доставлена."
            >
              <template v-slot:input>
                <notificaton-select
                  v-model="stepperData.fallback_notification_id"
                  :filter-items="(x) => x.id !== stepperData.id && x.channel_type !== stepperData.channel_type"
                  outlined
                  hide-details
                  clearable
                />
              </template>
            </BaseMasterFieldBlock>
            <push-database-content
              v-if="isChannelType('PUSH_DATABASE')"
              :stepper-data="stepperData"
            />
            <email-content
              v-else-if="isChannelType('EMAIL')"
              :stepper-data="stepperData"
            />
            <sms-content
              v-else-if="isChannelType('SMS')"
              :stepper-data="stepperData"
            />
            <apple-wallet-content
              v-else-if="isChannelType('APPLE_WALLET')"
              :stepper-data="stepperData"
            />
            <whats-app-content
              v-else-if="isChannelType('WHATS_APP')"
              :stepper-data="stepperData"
            />
            <eda-push-content
              v-else-if="isChannelType('EDA')"
              :stepper-data="stepperData"
            />
          </v-form>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  // components
  import PushDatabaseContent from './content/PushDatabaseContent'
  import SmsContent from './content/SmsContent'
  import AppleWalletContent from './content/AppleWalletContent'
  import EmailContent from './content/EmailContent'
  import NotificatonSelect from '@/components/ModelSelect/NotificatonSelect'
  import WhatsAppContent from './content/WhatsAppContent'
  import EdaPushContent from './content/EdaPushContent'
  // other
  import { mapGetters } from 'vuex'
  import { deepClone } from '@/utils'

  export default {
    components: {
      PushDatabaseContent,
      SmsContent,
      AppleWalletContent,
      EmailContent,
      NotificatonSelect,
      WhatsAppContent,
      EdaPushContent,
    },
    props: {
      stepperData: {
        type: Object,
        default: () => {
          return {}
        },
      },
    },
    inject: ['isEdit', 'isCopy', 'rules'],
    data () {
      return {
        isFormValid: false,
        channelTypes: [
          { value: 'PUSH_DATABASE', text: 'PUSH-уведомление' },
          { value: 'SMS', text: 'SMS-сообщение' },
          { value: 'EMAIL', text: 'EMAIL-сообщение' },
          { value: 'APPLE_WALLET', text: 'Apple Wallet' },
          { value: 'WHATS_APP', text: 'WhatsApp' },
          { value: 'EDA', text: 'Приложение eDA' },
        ],
      }
    },
    computed: {
      ...mapGetters({
        programId: 'programId',
      }),
    },
    watch: {
      'stepperData.channel_type' (v) {
        this.stepperData.fallback_notification_id = null

        if (v === 'EMAIL' || v === 'PUSH_DATABASE') {
          this.setStepperData('content', {
            title: '',
            description: '',
            design: {},
            body: '',
          })
        } else if (v === 'SMS' || v === 'APPLE_WALLET' || v === 'WHATS_APP') {
          this.setStepperData('content', {
            body: '',
          })
        } else if (v === 'EDA') {
          this.setStepperData('content', {
            title: '',
            body: '',
          })
        }
      },
    },
    mounted () {
      if (!this.isEdit && !this.isCopy) {
        if (this.isChannelType('PUSH_DATABASE') || this.isChannelType('EMAIL')) {
          this.stepperData.content.title = ''
          this.stepperData.content.description = ''
        }
        this.stepperData.content.body = ''
      }

      if (!this.isEdit) {
        this.stepperData.program_id = this.programId
      }
    },
    methods: {
      isChannelType (type) {
        return this.stepperData.channel_type === type
      },
      setStepperData (keys, values) {
        const stepperData = deepClone(this.stepperData)
        if (Array.isArray(keys)) {
          keys.forEach((key, idx) => {
            stepperData[key] = values[idx]
          })
        } else {
          stepperData[keys] = values
        }
        this.$emit('update:stepperData', stepperData)
      },
    },
  }
</script>

<style lang="scss" scoped>
@import "@/styles/_typography";
@import "@/styles/vuetify-preset-plus/light_theme/_variables.sass";

.container__main-step {
  .field__name {
    max-width: 328px;
    input {
      @include body-m-medium;
      &::placeholder {
        @include body-m-medium;
        color: $neutral-600;
      }
    }
  }
}
</style>
