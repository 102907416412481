import { mapActions, mapGetters } from 'vuex'

export default {
  data () {
    return {
      showSubtitationMenu: false,
      showContextMenu: {
        title: false,
        description: false,
        body: false,
      },
    }
  },
  computed: {
    ...mapGetters({
      programId: 'programId',
    }),
  },
  watch: {

  },
  async mounted () {
    await this.fetchData()
  },
  methods: {
    ...mapActions({
      fetchAdditionalFields: 'settings/account-fields/GetFields',
      fetchBonusUnits: 'company/bonus_units/loadBonusUnits',
    }),
    async fetchData () {
      await this.fetchAdditionalFields({ id: this.programId, withStub: false })
      await this.fetchBonusUnits(this.programId)
    },
    contextMenuSubtitation (e, name, on) {
      e.preventDefault()
      on.click(e)
      this.showContextMenu[name] = true
    },
    pasteInTextField (ref, value) {
      const input = ref.$el.querySelector('input')
      const start = input.selectionStart
      const end = input.selectionEnd
      const finText = input.value.substring(0, start) + value + input.value.substring(end)
      ref.internalValue = finText
      input.selectionEnd = (start === end) ? (end + value.length) : end
    },
    pasteInTextArea (ref, value) {
      const input = ref.$el.querySelector('textarea')
      const start = input.selectionStart
      const end = input.selectionEnd
      const finText = input.value.substring(0, start) + value + input.value.substring(end)
      ref.$children[0].internalValue = finText
      input.selectionEnd = (start === end) ? (end + value.length) : end
    },
  },
}
