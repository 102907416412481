<template>
  <div>
    <BaseMasterFieldBlock title="Файл">
          <template v-slot:input>
                <WhatsAppImage 
                :notificationId="stepperData.id" 
                :loadFileAtributes="loadFileAtributes"
                @removeUploadFile="onRemoveUploadFile" 
                @updateFile="onUpdateFile" class="mb-1"/>
          </template>
    </BaseMasterFieldBlock>
    <BaseMasterFieldBlock title="Содержание">
      <template v-slot:description>
        <subtitation-menu>
          <template v-slot:activator="{ on, attrs }">
            <div class="d-flex align-center justify-space-between">
              <v-btn
                style="margin-left: 14px"
                color="secondary"
                :text="true"
                :ripple="false"
                v-bind="attrs"
                v-on="on"
              >
                Подстановки
              </v-btn>
              <!-- <v-btn
                style="margin-left: 14px"
                color="secondary"
                :text="false"
                :ripple="true"
                @click="clickSendTemplate"
                :loading="loadingSend"
                :disabled="(stepperData.content.body == null || stepperData.content.body == '') ? true : false"
              >
                Отправить себе
              </v-btn> -->
            </div>
          </template>
        </subtitation-menu>
      </template>
      <template v-slot:input>
        <subtitation-menu
          v-model="showContextMenu.title"
          @clickOnItem="pasteInTextArea($refs.fieldBody, $event)"
        >
          <template v-slot:activator="{ on }">
            <base-text-area
              :maxlength="MAX_LENGTH_BODY"
              ref="fieldBody"
              v-model="stepperData.content.body"
              :rules="rules.contentBody"
              :export-file-name="stepperData.name"
              placeholder="Введите содержание "
              counter
              @contextmenu="contextMenuSubtitation($event, 'body', on)"
              @blur="
                stepperData.content.body = stepperData.content.body
                  ? stepperData.content.body.trim()
                  : null
              "
            >
              <template v-slot:counter>
                <div class="d-flex flex-column">
                  <p class="body-s-regular neutral-600--text mb-0">
                    Кол-во символов:
                    {{ getLetterCount(stepperData.content.body) }} /
                    {{ MAX_LENGTH_BODY }}
                  </p>
                </div>
              </template>
            </base-text-area>
          </template>
        </subtitation-menu>
      </template>
    </BaseMasterFieldBlock>
  </div>
</template>

<script>
import SubtitationMixin from "./subtitation";
import SubtitationMenu from "./components/SubtitationMenu";
import WhatsAppImage from "./components/WhatsAppImage";
import Vue from "vue";
import { mapActions } from "vuex";

export default {
  components: {
    SubtitationMenu,
    WhatsAppImage
  },
  mixins: [SubtitationMixin],
  props: {
    stepperData: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  inject: ["rules"],
  mounted() {
    console.log('this.stepperData')
    console.log(this.stepperData);
    Vue.set(
      this.stepperData,
      "content",
      Object.assign(
        {
          body: null,
        },
        this.stepperData.content
      )
    );
  },
  data() {
    return {
      MAX_LENGTH_BODY: 65536,
      loadingSend: false,
      image: null
    };
  },
  methods: {
    ...mapActions({
      sendContentNotification: "company/notifications/sendContentNotification",
    }),
    onUpdateFile(file) {
      this.stepperData.content.file = file
    },
    onRemoveUploadFile() {
      this.stepperData.content.file = {}
    },
    async clickSendTemplate() {
      this.loadingSend = true;
      await this.sendContentNotification({
        channelType: this.stepperData.channel_type,
        content: { body: this.stepperData.content.body },
        programId: this.programId,
      });
      this.loadingSend = false;
    },
    getLetterCount(str) {
      return new TextEncoder().encode(this.strWithoutTemplate(str)).length;
    },
    strWithoutTemplate(str) {
      return str && str.replace(/{{\s*[\d\w\s\\.\\[\]]+\s*}}/g, "");
    },
  },
  computed: {
    loadFileAtributes() {
      if (this.stepperData.content.file) {
        return {
          url: this.stepperData.content.file.url,
          mime_type: this.stepperData.content.file.mime_type,
          original_name: this.stepperData.content.file.original_name,
        }
      } else {
        return {
          url: "",
          mime_type: "",
          original_name: "",
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
