var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('BaseMasterFieldBlock',{attrs:{"title":"Заголовок"},scopedSlots:_vm._u([{key:"description",fn:function(){return [_vm._v(" Заголовок PUSH-уведомления. "),_c('subtitation-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"margin-left":"14px"},attrs:{"color":"secondary","text":true,"ripple":false}},'v-btn',attrs,false),on),[_vm._v(" Подстановки ")])]}}])})]},proxy:true},{key:"input",fn:function(){return [_c('subtitation-menu',{on:{"clickOnItem":function($event){return _vm.pasteInTextField(_vm.$refs.fieldTitle, $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('base-text-field',{ref:"fieldTitle",attrs:{"placeholder":"Введите название заголовка","rules":_vm.rules.contentTitle,"maxlength":_vm.MAX_LENGTH_TITLE,"validation-placement":"top","error-style":"custom"},on:{"contextmenu":function($event){return _vm.contextMenuSubtitation($event, 'title', on)},"blur":function($event){_vm.stepperData.content.title = _vm.stepperData.content.title ? _vm.stepperData.content.title.trim() : null}},model:{value:(_vm.stepperData.content.title),callback:function ($$v) {_vm.$set(_vm.stepperData.content, "title", $$v)},expression:"stepperData.content.title"}})]}}]),model:{value:(_vm.showContextMenu.title),callback:function ($$v) {_vm.$set(_vm.showContextMenu, "title", $$v)},expression:"showContextMenu.title"}})]},proxy:true}])}),_c('BaseMasterFieldBlock',{attrs:{"title":"Содержание"},scopedSlots:_vm._u([{key:"description",fn:function(){return [_c('subtitation-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',{staticClass:"d-flex align-center justify-space-between"},[_c('v-btn',_vm._g(_vm._b({staticStyle:{"margin-left":"14px"},attrs:{"color":"secondary","text":true,"ripple":false}},'v-btn',attrs,false),on),[_vm._v(" Подстановки ")]),_c('v-btn',{staticStyle:{"margin-left":"14px"},attrs:{"color":"secondary","text":false,"ripple":true,"loading":_vm.loadingSend,"disabled":(_vm.stepperData.content.body == null || _vm.stepperData.content.body == '') ? true : false},on:{"click":_vm.clickSendTemplate}},[_vm._v(" Отправить себе ")])],1)]}}])})]},proxy:true},{key:"input",fn:function(){return [_c('subtitation-menu',{on:{"clickOnItem":function($event){return _vm.pasteInTextArea(_vm.$refs.fieldBody, $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('base-text-area',{ref:"fieldBody",attrs:{"maxlength":_vm.MAX_LENGTH_BODY,"rules":_vm.rules.contentBody,"export-file-name":_vm.stepperData.name,"placeholder":"Введите содержание","counter":""},on:{"contextmenu":function($event){return _vm.contextMenuSubtitation($event, 'body', on)},"blur":function($event){_vm.stepperData.content.body = _vm.stepperData.content.body
                ? _vm.stepperData.content.body.trim()
                : null}},model:{value:(_vm.stepperData.content.body),callback:function ($$v) {_vm.$set(_vm.stepperData.content, "body", $$v)},expression:"stepperData.content.body"}})]}}]),model:{value:(_vm.showContextMenu.title),callback:function ($$v) {_vm.$set(_vm.showContextMenu, "title", $$v)},expression:"showContextMenu.title"}})]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }