<template>
  <v-row>
    <v-col cols="12">
      <v-alert
      outlined
      type="info"
    >Ограничения WhatsApp: при отправке файла-документа текст сообщения не отображается</v-alert> 
    </v-col>
    <v-col v-if="loadFileAtributes.original_name" cols="12">
      <v-card>
        <v-list >
          <v-list-item-group color="primary">
            <v-list-item>
              <v-list-item-icon>
                <v-icon color="primary">mdi-file-document</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>
                    {{ loadFileAtributes.original_name }}
                </v-list-item-title>
              </v-list-item-content>

              <v-list-item-icon>
                <v-icon color="primary" @click="downloadFile" class="mr-1">mdi-download</v-icon>
                <v-icon color="primary" @click="removeImage">mdi-delete</v-icon>
              </v-list-item-icon>

            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-card>
    </v-col>
    <v-col
      v-if="imageUrl || loadFileAtributes.url && fileTypeImg"
      class="d-flex child-flex"
      cols="4"
      style="position: relative; display: inline-block"
    >
      <v-img
        :src="imgSrc"
        aspect-ratio="1"
        class="grey lighten-2"
      >
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular
              indeterminate
              color="grey lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>
      </v-img>
    </v-col>

     <v-col v-if="!loadFileAtributes.url" cols="12">
      <div>
        <v-row justify="center" align="center">
          <v-file-input
            :rules="[
              (value) =>
                !value ||
                value.size < 20971520 ||
                'Максимальный размер 20Мб',
            ]"
            :accept="accept"
            truncate-length="50"
            @change="updateImg"
            @click:clear="removeImage"
            v-model="image"
            show-size
            color="primary"
            label="Выбранный файл"
            outlined
          ></v-file-input>
        </v-row>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      image: null,
      imageUrl: null,
      accept:
        "audio/mpeg, audio/ogg, video/mp4, image/gif, image/png, image/jpeg, image/jpg, audio/mp3, audio/wav, application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/msword, text/csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel",
    };
  },
  props: {
    loadFileAtributes: {
      type: Object,
      default: {
        url: "",
        mime_type: "",
        original_name: "",
      },
    },
    notificationId: {
      type: Number,
      default: null,
    },
  },
  methods: {
    ...mapActions({
      deleteFile: "company/notifications/deleteImageWhatsApp",
    }),
    updateImg() {
      let typeImage;
      if (this.image && this.image.type.split("/")[0] == "image") {
        typeImage = true;
      } else {
        typeImage = false;
      }
      this.readAndPreview(this.image, typeImage);
    },
    readAndPreview(file, typeImage) {
      if (file && typeImage) {
        const reader = new FileReader();
        let that = this;
        reader.addEventListener(
          "load",
          function () {
            that.imageUrl = this.result;
          },
          false
        );
        reader.readAsDataURL(file);
      }
    },

    downloadFile() {
      window.open(this.loadFileAtributes.url)
    },

    async removeImage() {
      if (this.loadFileAtributes.url) {
        try {
          await this.$confirm(
            `Вы уверены, что хотите удалить файл?`,
            "Удаление файла",
            {
              confirmButtonText: "Удалить",
              cancelButtonText: "Отмена",
              type: "warning",
            }
          );
          await this.deleteFile({ id: this.notificationId });
          this.loadFileAtributes = {
            url: "",
            mime_type: "",
            original_name: "",
          };
          this.$emit("removeUploadFile", this.image);
        } catch (e) {
          console.log(e);
        }
      }
      this.imageUrl = null;
      this.image = null;
    },
  },
  computed: {
    getUrlsFiles() {
      const reader = new FileReader();
      urlsArray = [];
      this.images.forEach((file) => urlsArray.push(reader.readAsDataURL(file)));
      return urlsArray;
    },
    imgSrc() {
      return this.imageUrl || this.loadFileAtributes.url;
    },
    fileTypeImg() {
      if (this.loadFileAtributes.mime_type && this.loadFileAtributes.mime_type.split("/")[0] == "image") { 
        return true;
      } else {
        return false;
      }
    }
  },
  watch: {
    image() {
      this.$emit("updateFile", this.image);
    },
  },
};
</script>