var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('BaseMasterFieldBlock',{attrs:{"title":"Заголовок","description":"Заголовок рассылки, который увидит клиент."},scopedSlots:_vm._u([{key:"description",fn:function(){return [_vm._v(" Заголовок рассылки, который увидит клиент. "),_c('subtitation-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"margin-left":"14px"},attrs:{"color":"secondary","text":true,"ripple":false}},'v-btn',attrs,false),on),[_vm._v(" Подстановки ")])]}}])})]},proxy:true},{key:"input",fn:function(){return [_c('subtitation-menu',{on:{"clickOnItem":function($event){return _vm.pasteInTextField(_vm.$refs.fieldTitle, $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('base-text-field',{ref:"fieldTitle",attrs:{"placeholder":"Введите название заголовока","rules":_vm.rules.contentTitle,"validation-placement":"top"},on:{"contextmenu":function($event){return _vm.contextMenuSubtitation($event, 'title', on)},"blur":function($event){_vm.stepperData.content.title = _vm.stepperData.content.title ? _vm.stepperData.content.title.trim() : null}},model:{value:(_vm.stepperData.content.title),callback:function ($$v) {_vm.$set(_vm.stepperData.content, "title", $$v)},expression:"stepperData.content.title"}})]}}]),model:{value:(_vm.showContextMenu.title),callback:function ($$v) {_vm.$set(_vm.showContextMenu, "title", $$v)},expression:"showContextMenu.title"}})]},proxy:true}])}),_c('BaseMasterFieldBlock',{attrs:{"title":"Прехедер","description":"Описание для прехедера."},scopedSlots:_vm._u([{key:"description",fn:function(){return [_vm._v(" Это специальная строка, которая не отображается в самом сообщении, но видна в списке всех сообщений в почтовой программе клиента. "),_c('subtitation-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"margin-left":"14px"},attrs:{"color":"secondary","text":true,"ripple":false}},'v-btn',attrs,false),on),[_vm._v(" Подстановки ")])]}}])})]},proxy:true},{key:"input",fn:function(){return [_c('subtitation-menu',{on:{"clickOnItem":function($event){return _vm.pasteInTextArea(_vm.$refs.fieldDescription, $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('base-text-field',{ref:"fieldDescription",attrs:{"placeholder":"Введите прехедер","rules":_vm.rules.contentDescription,"validation-placement":"top"},on:{"contextmenu":function($event){return _vm.contextMenuSubtitation($event, 'description', on)},"blur":function($event){_vm.stepperData.content.description = _vm.stepperData.content.description ? _vm.stepperData.content.description.trim() : null}},model:{value:(_vm.stepperData.content.description),callback:function ($$v) {_vm.$set(_vm.stepperData.content, "description", $$v)},expression:"stepperData.content.description"}})]}}]),model:{value:(_vm.showContextMenu.description),callback:function ($$v) {_vm.$set(_vm.showContextMenu, "description", $$v)},expression:"showContextMenu.description"}})]},proxy:true}])}),_c('BaseMasterFieldBlock',{attrs:{"title":"Содержание","description":""},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pa-0"},[_c('base-html-editor',{ref:"htmleditor",attrs:{"export-file-name":_vm.stepperData.name,"html-text":_vm.htmlText,"mode":_vm.editorMode,"rules":_vm.rules.contentBody},on:{"update:htmlText":function($event){_vm.htmlText=$event},"update:html-text":function($event){_vm.htmlText=$event},"update:mode":function($event){_vm.editorMode=$event},"loadEmailEditor":_vm.loadEmailEditor},scopedSlots:_vm._u([{key:"append-action",fn:function(){return [_c('subtitation-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"secondary"}},'v-btn',attrs,false),on),[_vm._v(" Подстановки ")])]}}])})]},proxy:true},{key:"action",fn:function(){return [_c('v-btn',{staticStyle:{"margin-left":"14px"},attrs:{"color":"secondary","loading":_vm.loadingSend},on:{"click":_vm.clickSendTemplateToYourself}},[_vm._v(" Отправить себе ")])]},proxy:true}]),model:{value:(_vm.editorValue),callback:function ($$v) {_vm.editorValue=$$v},expression:"editorValue"}})],1)],1)]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }