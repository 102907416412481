var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('BaseMasterFieldBlock',{attrs:{"title":"Файл"},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('WhatsAppImage',{staticClass:"mb-1",attrs:{"notificationId":_vm.stepperData.id,"loadFileAtributes":_vm.loadFileAtributes},on:{"removeUploadFile":_vm.onRemoveUploadFile,"updateFile":_vm.onUpdateFile}})]},proxy:true}])}),_c('BaseMasterFieldBlock',{attrs:{"title":"Содержание"},scopedSlots:_vm._u([{key:"description",fn:function(){return [_c('subtitation-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',{staticClass:"d-flex align-center justify-space-between"},[_c('v-btn',_vm._g(_vm._b({staticStyle:{"margin-left":"14px"},attrs:{"color":"secondary","text":true,"ripple":false}},'v-btn',attrs,false),on),[_vm._v(" Подстановки ")])],1)]}}])})]},proxy:true},{key:"input",fn:function(){return [_c('subtitation-menu',{on:{"clickOnItem":function($event){return _vm.pasteInTextArea(_vm.$refs.fieldBody, $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('base-text-area',{ref:"fieldBody",attrs:{"maxlength":_vm.MAX_LENGTH_BODY,"rules":_vm.rules.contentBody,"export-file-name":_vm.stepperData.name,"placeholder":"Введите содержание ","counter":""},on:{"contextmenu":function($event){return _vm.contextMenuSubtitation($event, 'body', on)},"blur":function($event){_vm.stepperData.content.body = _vm.stepperData.content.body
                ? _vm.stepperData.content.body.trim()
                : null}},scopedSlots:_vm._u([{key:"counter",fn:function(){return [_c('div',{staticClass:"d-flex flex-column"},[_c('p',{staticClass:"body-s-regular neutral-600--text mb-0"},[_vm._v(" Кол-во символов: "+_vm._s(_vm.getLetterCount(_vm.stepperData.content.body))+" / "+_vm._s(_vm.MAX_LENGTH_BODY)+" ")])])]},proxy:true}],null,true),model:{value:(_vm.stepperData.content.body),callback:function ($$v) {_vm.$set(_vm.stepperData.content, "body", $$v)},expression:"stepperData.content.body"}})]}}]),model:{value:(_vm.showContextMenu.title),callback:function ($$v) {_vm.$set(_vm.showContextMenu, "title", $$v)},expression:"showContextMenu.title"}})]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }