import { render, staticRenderFns } from "./SubtitationMenu.vue?vue&type=template&id=9dbde7e2&scoped=true&"
import script from "./SubtitationMenu.vue?vue&type=script&lang=js&"
export * from "./SubtitationMenu.vue?vue&type=script&lang=js&"
import style0 from "./SubtitationMenu.vue?vue&type=style&index=0&id=9dbde7e2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9dbde7e2",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListGroup } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
installComponents(component, {VIcon,VList,VListGroup,VListItem,VListItemContent,VListItemTitle,VMenu})
